import dayjs from 'dayjs';
import Config from '@/config/config';

import Vue from 'vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast);

import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

const CONFIG = new Config();

export const listAll = async (mesRef) => {
  var axios = require('axios');

  var data = {
    mesRef: mesRef
  };

  var config = {
    method: 'post',
    url: `${CONFIG.API_URL_LIBRACCEE}/statusMedicoes/getRedShiftMed5MinStatus`,
    headers: {
      'Content-Type': 'application/json',
      token: CONFIG.LIBRACCEE_TOKEN
    },
    data: data
  };

  return axios(config)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);

      return null;
    });
};

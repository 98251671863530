<template>
  <div>
    <PageHeader :title="'Qualidade Medições 5 min'" :items="breadcrumb" />

    <b-card class="mb-3 mt-3">
      <b-container fluid class="ml-3 mt-2">
        <b-row align-v="center" class="my-3">
          <b-col md="4">
            <b-form-group label="Mês de Referência" label-for="referenciaMes">
              <b-form-select
                id="referenciaMes"
                v-model="referenciaMes"
                :options="meses"
                @change="atualizarReferencia"
              />
            </b-form-group>
          </b-col>

          <b-col md="4">
            <b-form-group label="Ano de Referência" label-for="referenciaAno">
              <b-form-input
                id="referenciaAno"
                type="number"
                v-model="referenciaAno"
                @change="atualizarReferencia"
              />
            </b-form-group>
          </b-col>

          <b-col md="4" class="d-flex justify-content">
            <b-button @click="buscarDados" variant="success" class="mt-2"
              >Buscar</b-button
            >
            <b-spinner v-if="carregando" class="ml-2 mt-3" />
          </b-col>
        </b-row>
      </b-container>
    </b-card>
    <MedicoesTabela
      :dadosPontos="dadosPontos"
      :carregando="carregando"
      :medicoesEsperadas="medicoesEsperadas"
      measurementType="5min"
    />
  </div>
</template>

<script>
import PageHeader from '@/components/page-header';
import { listAll } from './qualidadeMedicoes5Min.service';
import meses from '@/utils/meses';
import MedicoesTabela from './qualidadeMedicoesTable';

export default {
  data() {
    return {
      referenciaMes: new Date().getMonth() + 1,
      referenciaAno: new Date().getFullYear(),
      meses: meses(),
      breadcrumb: [
        {
          text: 'CCEE',
          href: '#'
        },
        {
          text: 'MED5m',
          href: '#'
        },
        {
          text: 'Qualidade Medições',
          active: true
        }
      ],
      medicoesEsperadas: 0,
      carregando: false,
      dadosPontos: []
    };
  },
  methods: {
    atualizarReferencia() {},
    buscarDados() {
      this.carregando = true;
      const mesAnoReferencia = `${this.referenciaAno}-${String(
        this.referenciaMes
      ).padStart(2, '0')}-01`;

      listAll(mesAnoReferencia)
        .then((resposta) => {
          console.log(resposta);
          if (resposta && resposta.success && resposta.content) {
            this.dadosPontos = resposta.content.dadosPontos;
            this.medicoesEsperadas = resposta.content.medidasEsperadas;
          } else {
            console.error(
              'Resposta do servidor não está no formato esperado:',
              resposta
            );
            this.dadosPontos = [];
            this.medicoesEsperadas = 0;
          }
        })
        .catch((error) => {
          console.error('Erro ao buscar dados:', error);
          this.dadosPontos = [];
          this.medicoesEsperadas = 0;
        })
        .finally(() => {
          this.carregando = false;
        });
    }
  },
  components: {
    PageHeader,
    MedicoesTabela
  }
};
</script>
